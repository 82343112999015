<template>
	<div>
		<stop-modals :modelName="modelName"></stop-modals>
		<pui-form-header v-if="modelLoaded" :showHeader="!isCreatingElement">
			<stop-form-header :modelPk="modelPk"></stop-form-header>
		</pui-form-header>
		<v-tabs v-model="modelName" class="ml-3 mb-3" slider-color="primary">
			<v-tab :key="0" :href="'#maintab'">{{ $t('stop.tabs.maintab') }}</v-tab>
			<v-tab :key="1" :href="'#operations'" v-if="!isCreatingElement && hasOperationFunctionality">{{ $t('stop.tabs.operations') }}</v-tab>
			<v-tab :key="2" :href="'#resourceallocation'" v-if="!isCreatingElement && !isModalDialog && hasResAllocationFunctionality">{{
				$t('stop.tabs.resourceallocation')
			}}</v-tab>
		</v-tabs>
		<v-tabs-items v-model="modelName">
			<v-tab-item :key="0" lazy :value="'maintab'">
				<v-row dense class="pui-form-layout">
					<v-col cols="8">
						<pui-field-set :title="$t('stop.data')">
							<v-row dense>
								<v-col cols="2">
									<pui-number-field
										:id="`num-${modelName}`"
										:label="$t('stop.stopnum')"
										v-model="model.num"
										maxlength="4"
										decimals="0"
										min="0"
										max="999999999"
										toplabel
										:disabled="true"
									></pui-number-field>
								</v-col>
								<v-col cols="3">
									<pui-select
										:id="`status-${modelName}`"
										:attach="`status-${modelName}`"
										:label="$t('stop.stopStatus')"
										toplabel
										clearable
										required
										:disabled="true"
										v-model="model"
										modelName="stopstatuspa"
										:itemsToSelect="itemsToSelectStatus"
										:modelFormMapping="{ stopstatuscode: 'status' }"
										itemValue="stopstatuscode"
										itemText="stopstatusname"
										reactive
									></pui-select>
								</v-col>
								<v-col cols="1" class="align-self-center">
									<pui-checkbox
										:id="`manual-${modelName}`"
										:label="$t('stop.manual')"
										v-model="model.manual"
										:disabled="true"
									></pui-checkbox>
								</v-col>
								<v-col cols="2" class="align-self-center">
									<pui-checkbox
										:id="`exemptpilotage-${modelName}`"
										:label="$t('stop.exemptpilotage')"
										v-model="model.exemptpilotage"
										:disabled="true"
									></pui-checkbox>
								</v-col>
								<v-col cols="3" class="align-self-center">
									<pui-checkbox
										:id="`extendedstay-${modelName}`"
										:label="$t('stop.extendedstay')"
										v-model="model.extendedstay"
										:disabled="true"
									></pui-checkbox>
								</v-col>
								<v-col cols="1" class="align-self-center">
									<pui-checkbox
										:id="`fault-${modelName}`"
										:label="$t('stop.fault')"
										v-model="model.fault"
										:disabled="true"
									></pui-checkbox>
								</v-col>
							</v-row>
							<v-row dense>
								<v-col cols="7">
									<pui-text-area
										v-if="model.commentsstatus"
										:id="`commentsstatus-stop`"
										v-model="model.commentsstatus"
										:label="$t('stop.commentsstatus')"
										:disabled="true"
										toplabel
										maxlength="350"
									></pui-text-area>
								</v-col>
							</v-row>
						</pui-field-set>
					</v-col>
					<v-col cols="4">
						<pui-field-set :title="$t('stop.supplies')">
							<v-row dense>
								<v-col cols="2" class="my-7 align-self-center">
									<pui-checkbox
										:id="`water-${modelName}`"
										:label="$t('stop.water')"
										v-model="model.water"
										:disabled="true"
									></pui-checkbox>
								</v-col>
								<v-col cols="2" class="align-self-center">
									<pui-checkbox
										:id="`ice-${modelName}`"
										:label="$t('stop.ice')"
										v-model="model.ice"
										:disabled="true"
									></pui-checkbox>
								</v-col>
								<v-col cols="3" class="align-self-center">
									<pui-checkbox
										:id="`fuel-${modelName}`"
										:label="$t('stop.fuel')"
										v-model="model.fuel"
										:disabled="true"
									></pui-checkbox>
								</v-col>
								<v-col cols="4" class="align-self-center">
									<pui-checkbox
										:id="`electricity-${modelName}`"
										:label="$t('stop.electricity')"
										v-model="model.electricity"
										:disabled="true"
									></pui-checkbox>
								</v-col>
							</v-row>
						</pui-field-set>
					</v-col>
				</v-row>
				<v-row dense class="pui-form-layout">
					<v-col cols="12">
						<pui-field-set :title="$t('stop.requesteddata')">
							<v-row dense>
								<v-col cols="3">
									<pui-date-field
										:id="`eta-${modelName}`"
										:label="$t('stop.eta')"
										v-model="model.eta"
										toplabel
										required
										:disabled="true"
										time
									></pui-date-field>
								</v-col>
								<v-col cols="3">
									<pui-date-field
										:id="`etd-${modelName}`"
										:label="$t('stop.etd')"
										v-model="model.etd"
										toplabel
										required
										:disabled="true"
										time
									></pui-date-field>
								</v-col>
								<v-col cols="3">
									<pui-date-field
										:id="`pbaestimated-${modelName}`"
										:label="$t('stop.pbaestimated')"
										v-model="model.pbaestimated"
										toplabel
										:disabled="true"
										time
									></pui-date-field>
								</v-col>
								<v-col cols="3">
									<pui-select
										:id="`berthingtypereq-${modelName}`"
										:attach="`berthingtypereq-${modelName}`"
										:label="$t('stop.berthingtypereq')"
										toplabel
										clearable
										required
										v-model="model"
										modelName="berthingtype"
										:itemsToSelect="itemsToSelectBerthingtypereq"
										:modelFormMapping="{ berthingtypecode: 'berthingtypereq' }"
										itemValue="berthingtypecode"
										itemText="berthingtypename"
										:order="{ berthingtypename: 'asc' }"
										reactive
										:disabled="true"
									></pui-select>
								</v-col>
								<v-col cols="3">
									<pui-text-area
										v-if="modelLoaded && this.modelBerthingType.indanchoring === 1"
										:id="`reasonanchorage-stop`"
										:label="$t('stop.reasonanchorage')"
										v-model="model.reasonanchorage"
										toplabel
										:disabled="true"
										maxlength="300"
									></pui-text-area>
								</v-col>
							</v-row>
							<v-row dense>
								<v-col cols="3">
									<pui-select
										:id="`locationid-${modelName}`"
										:attach="`locationid-${modelName}`"
										:label="$t('stop.locationid')"
										toplabel
										clearable
										required
										:disabled="true"
										v-model="model"
										modelName="berth"
										:itemsToSelect="itemsToSelectBerthreq"
										:modelFormMapping="{ id: 'locationid' }"
										itemValue="id"
										itemText="name"
										:order="{ name: 'asc' }"
										reactive
									></pui-select>
								</v-col>
								<v-col cols="1">
									<pui-select
										:id="`bollardini-${modelName}`"
										:attach="`bollardini-${modelName}`"
										:label="$t('stop.bollardini')"
										toplabel
										:disabled="true"
										v-model="model"
										modelName="bollard"
										:itemsToSelect="[{ id: this.model.bollardini }]"
										:modelFormMapping="{ id: 'bollardini' }"
										itemValue="id"
										itemText="bolcode"
										:order="{ orderby: 'asc' }"
										reactive
										:fixedFilter="bollardIniReqFixedFilter"
									></pui-select>
								</v-col>
								<v-col cols="1">
									<pui-select
										:id="`bollardend-${modelName}`"
										:attach="`bollardend-${modelName}`"
										:label="$t('stop.bollardend')"
										toplabel
										:disabled="true"
										v-model="model"
										modelName="bollard"
										:itemsToSelect="[{ id: this.model.bollardend }]"
										:modelFormMapping="{ id: 'bollardend' }"
										itemValue="id"
										itemText="bolcode"
										:order="{ orderby: 'asc' }"
										reactive
										:fixedFilter="bollardEndReqFixedFilter"
									></pui-select>
								</v-col>
								<v-col cols="3">
									<pui-select
										:id="`activitytypereq-${modelName}`"
										:attach="`activitytypereq-${modelName}`"
										:label="$t('stop.activitytypereq')"
										toplabel
										clearable
										required
										:disabled="true"
										v-model="model"
										modelName="activitytype"
										:itemsToSelect="itemsToSelectActivitytypereq"
										:modelFormMapping="{ code: 'activitytypereq' }"
										itemValue="code"
										itemText="name"
										:order="{ name: 'asc' }"
										reactive
									></pui-select>
								</v-col>
								<v-col cols="2">
									<pui-number-field
										:id="`draftentrance-${modelName}`"
										:label="$t('stop.draftentrance')"
										v-model="model.draftentrance"
										maxlength="5"
										decimals="2"
										min="0"
										max="999.99"
										toplabel
										:disabled="true"
									></pui-number-field>
								</v-col>
								<v-col cols="2">
									<pui-number-field
										:id="`draftoutlet-${modelName}`"
										:label="$t('stop.draftoutlet')"
										v-model="model.draftoutlet"
										maxlength="5"
										decimals="2"
										min="0"
										max="999.99"
										toplabel
										:disabled="true"
									></pui-number-field>
								</v-col>
							</v-row>
						</pui-field-set>
					</v-col>
				</v-row>
				<v-row dense class="pui-form-layout">
					<v-col cols="12">
						<pui-field-set :title="$t('stop.authorizeddata')">
							<v-row dense>
								<v-col cols="3">
									<pui-date-field
										:id="`etaauth-${modelName}`"
										:label="$t('stop.etaauth')"
										v-model="model.etaauth"
										toplabel
										:disabled="true"
										time
									></pui-date-field>
								</v-col>
								<v-col cols="3">
									<pui-date-field
										:id="`etdauth-${modelName}`"
										:label="$t('stop.etdauth')"
										v-model="model.etdauth"
										toplabel
										:disabled="true"
										time
									></pui-date-field>
								</v-col>
								<v-col cols="3">
									<pui-date-field
										:id="`pbaauthorized-${modelName}`"
										:label="$t('stop.pbaauthorized')"
										v-model="model.pbaauthorized"
										toplabel
										:disabled="true"
										time
									></pui-date-field>
								</v-col>
								<v-col cols="3">
									<pui-select
										:id="`berthingtypeauth-${modelName}`"
										:attach="`berthingtypeauth-${modelName}`"
										:label="$t('stop.berthingtypeauth')"
										toplabel
										clearable
										v-model="model"
										modelName="berthingtype"
										:itemsToSelect="itemsToSelectBerthingtypeauth"
										:modelFormMapping="{ berthingtypecode: 'berthingtypeauth' }"
										itemValue="berthingtypecode"
										itemText="berthingtypename"
										:order="{ berthingtypename: 'asc' }"
										reactive
										:disabled="true"
									></pui-select>
								</v-col>
							</v-row>
							<v-row dense>
								<v-col cols="4">
									<pui-select
										:id="`berthauth-${modelName}`"
										:attach="`berthauth-${modelName}`"
										:label="$t('stop.berthauth')"
										toplabel
										clearable
										:disabled="true"
										v-model="model"
										modelName="berth"
										:itemsToSelect="itemsToSelectBerthauth"
										:modelFormMapping="{ id: 'berthauth' }"
										itemValue="id"
										itemText="name"
										:order="{ name: 'asc' }"
										reactive
									></pui-select>
								</v-col>
								<v-col cols="2">
									<pui-select
										:id="`bollardiniauth-${modelName}`"
										:attach="`bollardiniauth-${modelName}`"
										:label="$t('stop.bollardiniauth')"
										toplabel
										:disabled="true"
										v-model="model"
										modelName="bollard"
										:itemsToSelect="[{ id: this.model.bollardiniauth }]"
										:modelFormMapping="{ id: 'bollardiniauth' }"
										itemValue="id"
										itemText="bolcode"
										:order="{ orderby: 'asc' }"
										reactive
										:fixedFilter="bollardIniAuthFixedFilter"
									></pui-select>
								</v-col>
								<v-col cols="2">
									<pui-select
										:id="`bollardendauth-${modelName}`"
										:attach="`bollardendauth-${modelName}`"
										:label="$t('stop.bollardendauth')"
										toplabel
										:disabled="true"
										v-model="model"
										modelName="bollard"
										:itemsToSelect="[{ id: this.model.bollardendauth }]"
										:modelFormMapping="{ id: 'bollardendauth' }"
										itemValue="id"
										itemText="bolcode"
										:order="{ orderby: 'asc' }"
										reactive
										:fixedFilter="bollardEndAuthFixedFilter"
									></pui-select>
								</v-col>
							</v-row>
						</pui-field-set>
					</v-col>
				</v-row>
				<v-row dense class="pui-form-layout">
					<v-col cols="12">
						<pui-field-set :title="$t('stop.actualdata')">
							<v-row dense>
								<v-col cols="3">
									<pui-date-field
										:id="`ata-${modelName}`"
										:label="$t('stop.ata')"
										v-model="model.ata"
										toplabel
										:disabled="true"
										time
									></pui-date-field>
								</v-col>
								<v-col cols="3">
									<pui-date-field
										:id="`atd-${modelName}`"
										:label="$t('stop.atd')"
										v-model="model.atd"
										toplabel
										:disabled="true"
										time
									></pui-date-field>
								</v-col>
								<v-col cols="3">
									<pui-select
										:id="`berthingtypeactual-${modelName}`"
										:attach="`berthingtypeactual-${modelName}`"
										:label="$t('stop.berthingtypeactual')"
										toplabel
										clearable
										v-model="model"
										modelName="berthingtype"
										:itemsToSelect="itemsToSelectBerthingtypeactual"
										:modelFormMapping="{ berthingtypecode: 'berthingtypeactual' }"
										itemValue="berthingtypecode"
										itemText="berthingtypename"
										:order="{ berthingtypename: 'asc' }"
										reactive
										:disabled="true"
									></pui-select>
								</v-col>
								<v-col cols="3">
									<pui-select
										:id="`activitytypeactual-${modelName}`"
										:attach="`activitytypeactual-${modelName}`"
										:label="$t('stop.activitytypeactual')"
										toplabel
										clearable
										:disabled="true"
										v-model="model"
										modelName="activitytype"
										:itemsToSelect="itemsToSelectActivitytypeactual"
										:modelFormMapping="{ code: 'activitytypeactual' }"
										itemValue="code"
										itemText="name"
										:order="{ name: 'asc' }"
										reactive
									></pui-select>
								</v-col>
							</v-row>
							<v-row dense>
								<v-col cols="4">
									<pui-select
										:id="`berthactual-${modelName}`"
										:attach="`berthactual-${modelName}`"
										:label="$t('stop.berthactual')"
										toplabel
										clearable
										:disabled="true"
										v-model="model"
										modelName="berth"
										:itemsToSelect="itemsToSelectBerthactual"
										:modelFormMapping="{ id: 'berthactual' }"
										itemValue="id"
										itemText="name"
										:order="{ name: 'asc' }"
										reactive
									></pui-select>
								</v-col>
								<v-col cols="2">
									<pui-select
										:id="`bollardiniactual-${modelName}`"
										:attach="`bollardiniactual-${modelName}`"
										:label="$t('stop.bollardiniactual')"
										toplabel
										:disabled="true"
										v-model="model"
										modelName="bollard"
										:itemsToSelect="[{ id: this.model.bollardiniactual }]"
										:modelFormMapping="{ id: 'bollardiniactual' }"
										itemValue="id"
										itemText="bolcode"
										:order="{ orderby: 'asc' }"
										reactive
										:fixedFilter="bollardIniActualFixedFilter"
									></pui-select>
								</v-col>
								<v-col cols="2">
									<pui-select
										:id="`bollardendactual-${modelName}`"
										:attach="`bollardendactual-${modelName}`"
										:label="$t('stop.bollardendactual')"
										toplabel
										:disabled="true"
										v-model="model"
										modelName="bollard"
										:itemsToSelect="[{ id: this.model.bollardendactual }]"
										:modelFormMapping="{ id: 'bollardendactual' }"
										itemValue="id"
										itemText="bolcode"
										:order="{ orderby: 'asc' }"
										reactive
										:fixedFilter="bollardEndActualFixedFilter"
									></pui-select>
								</v-col>
							</v-row>
						</pui-field-set>
					</v-col>
				</v-row>
				<v-row dense class="pui-form-layout">
					<v-col cols="12">
						<pui-text-area
							:id="`comments-stop`"
							v-model="model.comments"
							:label="$t('stop.comments')"
							:disabled="true"
							toplabel
							maxlength="300"
						></pui-text-area>
					</v-col>
				</v-row>
			</v-tab-item>
			<v-tab-item :key="1" :value="`operations`" lazy v-if="!isCreatingElement && hasOperationFunctionality">
				<v-flex xs12>
					<pui-master-detail
						componentName="operationgrid"
						:parentModelName="modelName"
						:parentPk="pk"
						:parentPkChildFk="{ id: 'stop_id' }"
						:formDisabled="formDisabled"
						:modalDialog="isModalDialog"
						:parentModel="model"
					></pui-master-detail>
				</v-flex>
			</v-tab-item>
			<v-tab-item :key="2" lazy :value="'resourceallocation'" v-if="!isCreatingElement && !isModalDialog && hasResAllocationFunctionality">
				<v-flex xs12>
					<pui-master-detail
						componentName="resourceallocationgrid"
						:parentModelName="modelName"
						:parentPk="pk"
						:parentPkChildFk="{ id: 'stop_id' }"
						:formDisabled="formDisabled"
						:modalDialog="isModalDialog"
						:parentModel="model"
					></pui-master-detail>
				</v-flex>
			</v-tab-item>
		</v-tabs-items>
		<!-- Page Footer -->
		<pui-form-footer v-if="!isModalDialog">
			<pui-form-footer-btns
				:formDisabled="formDisabled"
				:saveDisabled="saving"
				:saveAndNew="saveAndNew"
				:saveAndUpdate="saveAndUpdate"
				:save="save"
				:back="back"
			></pui-form-footer-btns>
		</pui-form-footer>
		<!-- </v-form> -->
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';
import StopActions from './StopActions.js';
import stopModals from './StopModals.vue';
import StopFormHeader from './StopFormHeader.vue';

export default {
	name: 'stop-form',
	mixins: [PuiFormMethodsMixin],
	components: {
		'stop-modals': stopModals,
		StopFormHeader
	},
	data() {
		return {
			modelName: 'stop',
			actions: StopActions.formactions,
			modelBerthingType: {}
		};
	},
	watch: {
		'model.berthingtypereq'(newValue) {
			this.getBerthingType();
		}
	},
	mounted() {
		this.getBerthingType();
	},
	methods: {
		getBerthingType() {
			const opts = {
				model: 'berthingtype',
				filter: {
					groupOp: 'and',
					groups: [],
					rules: [
						{
							field: 'berthingtypecode',
							op: 'eq',
							data: this.model.berthingtypereq
						}
					]
				}
			};
			this.$puiRequests.postRequest('/puisearch', opts, (response) => {
				if (response.data && response.data.data[0]) {
					this.modelBerthingType = response.data.data[0];
				}
			});
		}
	},

	computed: {
		itemsToSelectStatus() {
			return [{ stopstatuscode: this.model.status }];
		},
		itemsToSelectBerthingtypereq() {
			return [{ berthingtypecode: this.model.berthingtypereq }];
		},
		itemsToSelectBerthingtypeauth() {
			return [{ berthingtypecode: this.model.berthingtypeauth }];
		},
		itemsToSelectBerthingtypeactual() {
			return [{ berthingtypecode: this.model.berthingtypeactual }];
		},
		itemsToSelectBerthreq() {
			return [{ id: this.model.locationid }];
		},
		itemsToSelectBerthauth() {
			return [{ id: this.model.berthauth }];
		},
		itemsToSelectBerthactual() {
			return [{ id: this.model.berthactual }];
		},
		itemsToSelectActivitytypereq() {
			return [{ code: this.model.activitytypereq }];
		},
		itemsToSelectActivitytypeactual() {
			return [{ code: this.model.activitytypeactual }];
		},
		bollardIniReqFixedFilter() {
			return {
				groups: [],
				groupOp: 'and',
				rules: [{ field: 'berthid', op: 'eq', data: this.model.locationid }]
			};
		},
		bollardEndReqFixedFilter() {
			let berth = this.$store.getters.getRawPortBerths.find((berth) => berth.id == this.model.locationid);
			return {
				groups: [],
				groupOp: 'and',
				rules: [
					berth && berth.dockid
						? { field: 'dockid', op: 'eq', data: berth.dockid }
						: { field: 'berthid', op: 'eq', data: this.model.locationid }
				]
			};
		},
		bollardIniAuthFixedFilter() {
			return {
				groups: [],
				groupOp: 'and',
				rules: [{ field: 'berthid', op: 'eq', data: this.model.berthauth }]
			};
		},
		bollardEndAuthFixedFilter() {
			let berth = this.$store.getters.getRawPortBerths.find((berth) => berth.id == this.model.berthauth);
			return {
				groups: [],
				groupOp: 'and',
				rules: [
					berth && berth.dockid
						? { field: 'dockid', op: 'eq', data: berth.dockid }
						: { field: 'berthid', op: 'eq', data: this.model.berthauth }
				]
			};
		},
		bollardIniActualFixedFilter() {
			return {
				groups: [],
				groupOp: 'and',
				rules: [{ field: 'berthid', op: 'eq', data: this.model.berthactual }]
			};
		},
		bollardEndActualFixedFilter() {
			let berth = this.$store.getters.getRawPortBerths.find((berth) => berth.id == this.model.berthactual);
			return {
				groups: [],
				groupOp: 'and',
				rules: [
					berth && berth.dockid
						? { field: 'dockid', op: 'eq', data: berth.dockid }
						: { field: 'berthid', op: 'eq', data: this.model.berthactual }
				]
			};
		},
		hasOperationFunctionality() {
			return this.$store.getters.getSession.functionalities.includes('READ_OPERATION');
		},
		hasResAllocationFunctionality() {
			return this.$store.getters.getSession.functionalities.includes('READ_RESOURCEALLOCATION');
		}
	},
	created() {}
};
</script>
